import React from 'react';

const IconLogo = () => (
  <svg
    version="1.1"
    className="logoanim"
    id="logo"
    width="107"
    height="27"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 107 27">
    <defs>
      <linearGradient id="svg-gradient" gradientTransform="rotate(45)">
        <stop offset="0%" stopColor="var(--color-stop)" />
        <stop offset="50%" stopColor="var(--color-stop2)" />
        <stop offset="100%" stopColor="var(--color-bot)" />
      </linearGradient>
    </defs>
    <g id="buliga">
      <path
        id="B"
        className="letter"
        d="M2.4,0.4c0.9,0,1.6,0.2,2.2,0.5c0.5,0.4,0.8,0.9,0.8,1.7c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.3-0.6,0.6-1,0.8
		C3.6,4.8,3.2,4.9,2.6,4.9L2.4,4.2c0.7,0,1.3,0.1,1.8,0.3C4.7,4.7,5.1,5,5.4,5.3c0.3,0.4,0.4,0.8,0.4,1.3c0,0.5-0.1,0.8-0.3,1.2
		C5.4,8.1,5.2,8.3,4.9,8.5C4.6,8.7,4.3,8.9,3.9,9C3.6,9.1,3.2,9.1,2.8,9.1H0V0.4H2.4z M2.7,4.1c0.4,0,0.8-0.1,1-0.4
		c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.2-0.6-0.3-1-0.3H1.4v2.4H2.7z M2.7,7.8c0.3,0,0.6,0,0.8-0.1
		C3.8,7.6,4,7.5,4.1,7.3c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.3-0.7C3.9,5.7,3.7,5.6,3.4,5.5C3.2,5.5,2.9,5.4,2.6,5.4H1.4v2.4
		H2.7z"
      />
      <path
        id="U"
        className="letter"
        d="M21.2,6c0,0.3,0.1,0.7,0.3,0.9c0.2,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1.1-0.3
		c0.3-0.2,0.6-0.4,0.7-0.7c0.2-0.3,0.3-0.6,0.3-0.9V0.4h1.4V6c0,0.6-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.1
		c-0.5,0.3-1.1,0.4-1.8,0.4c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.9-0.6-1.2-1.1c-0.3-0.5-0.5-1-0.5-1.7V0.4h1.4V6z"
      />
      <path id="L" className="letter" d="M40.4,0.4h1.4v7.4h4.4v1.3h-5.8V0.4z" />
      <path id="I" className="letter" d="M61.6,0.4H63v8.7h-1.4V0.4z" />
      <path
        id="G"
        className="letter"
        d="M86.1,8.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.8,0.3C84.6,9,84.3,9.1,84,9.1c-0.3,0.1-0.6,0.1-0.8,0.1
		c-0.7,0-1.4-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9c-0.4-0.4-0.7-0.8-0.9-1.4c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.8,0.1-1.4,0.4-2
		c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.7,1.5-0.9c0.6-0.2,1.1-0.3,1.7-0.3c0.5,0,1,0.1,1.5,0.2c0.5,0.1,0.9,0.3,1.2,0.5l-0.5,1.3
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3,0-0.5-0.1-0.7-0.1c-0.5,0-1,0.1-1.4,0.2c-0.4,0.1-0.8,0.3-1,0.6
		c-0.3,0.3-0.5,0.6-0.7,1C80,3.9,80,4.3,80,4.8C80,5.3,80,5.7,80.2,6c0.2,0.4,0.4,0.7,0.7,0.9c0.3,0.3,0.6,0.5,1,0.6
		c0.4,0.1,0.8,0.2,1.3,0.2c0.3,0,0.5,0,0.8-0.1c0.3,0,0.5-0.1,0.7-0.2V6H83V4.7h3.1V8.3z"
      />
      <path
        id="A"
        className="letter"
        d="M99,9.1l4-9.1h0.1l4,9.1h-1.6l-2.8-6.9l1-0.7l-3.2,7.6H99z M101.5,5.8h3.1L105,7h-4L101.5,5.8z"
      />
    </g>
    <g id="dragos">
      <path
        id="S"
        className="letter"
        d="M106.4,19.9c-0.3-0.2-0.7-0.3-1-0.4c-0.4-0.1-0.7-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.4,0.5-0.4,0.8
		c0,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.5,0.3,0.7,0.5
		c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,1.1c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.4-0.6,0.7-1,0.9c-0.4,0.2-1,0.3-1.6,0.3
		c-0.4,0-0.7,0-1.1-0.1c-0.4-0.1-0.7-0.2-1-0.3c-0.3-0.1-0.6-0.3-0.9-0.5l0.6-1.1c0.2,0.1,0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.2,0.8,0.3
		c0.3,0.1,0.5,0.1,0.8,0.1c0.3,0,0.5,0,0.8-0.1s0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.5-0.2-0.6
		c-0.1-0.2-0.3-0.3-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.4c-0.3-0.1-0.6-0.2-0.8-0.3s-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.4-0.6-0.7
		c-0.2-0.3-0.2-0.6-0.2-1c0-0.5,0.1-0.9,0.3-1.2c0.2-0.4,0.5-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.6,0,1.2,0.1,1.6,0.2
		c0.4,0.1,0.8,0.3,1.2,0.5L106.4,19.9z"
      />
      <path
        id="O"
        className="letter"
        d="M78.7,22.5c0-0.6,0.1-1.2,0.4-1.7c0.2-0.5,0.6-1,1-1.4s0.9-0.7,1.5-1c0.6-0.2,1.1-0.4,1.8-0.4
		c0.6,0,1.2,0.1,1.8,0.4c0.6,0.2,1,0.6,1.5,1c0.4,0.4,0.8,0.9,1,1.4c0.2,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7
		c-0.2,0.5-0.6,1-1,1.4c-0.4,0.4-0.9,0.7-1.5,1c-0.6,0.2-1.1,0.3-1.8,0.3c-0.6,0-1.2-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.5-0.9
		c-0.4-0.4-0.7-0.9-1-1.4C78.8,23.8,78.7,23.2,78.7,22.5z M80.2,22.5c0,0.4,0.1,0.8,0.2,1.2c0.2,0.4,0.4,0.7,0.7,1
		c0.3,0.3,0.6,0.5,1,0.7s0.8,0.2,1.2,0.2c0.4,0,0.8-0.1,1.2-0.2c0.4-0.2,0.7-0.4,1-0.7c0.3-0.3,0.5-0.6,0.7-1
		c0.2-0.4,0.2-0.8,0.2-1.2c0-0.4-0.1-0.8-0.2-1.2s-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.8-0.2-1.2-0.2s-0.8,0.1-1.2,0.2
		c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.5,0.6-0.7,1C80.3,21.7,80.2,22.1,80.2,22.5z"
      />
      <path
        id="G2"
        className="letter"
        d="M67.1,26.1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2C64.6,27,64.4,27,64.1,27
		c-0.7,0-1.4-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9c-0.4-0.4-0.7-0.8-0.9-1.3c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.8,0.1-1.4,0.4-2
		c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.7,1.5-0.9c0.6-0.2,1.1-0.3,1.8-0.3c0.5,0,1,0.1,1.5,0.2c0.5,0.1,0.9,0.3,1.2,0.5L66.2,20
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3,0-0.5-0.1-0.7-0.1c-0.5,0-1,0.1-1.4,0.2c-0.4,0.1-0.8,0.3-1,0.6
		c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.2,0.8-0.2,1.3c0,0.4,0.1,0.9,0.2,1.2s0.4,0.7,0.7,0.9c0.3,0.3,0.6,0.5,1,0.6
		c0.4,0.1,0.8,0.2,1.3,0.2c0.3,0,0.5,0,0.8-0.1c0.3,0,0.5-0.1,0.7-0.2v-1.5h-1.7v-1.3h3.1V26.1z"
      />
      <path
        id="A2"
        className="letter"
        d="M40.2,26.9l4-9.1h0.1l4,9.1h-1.6L43.8,20l1-0.7l-3.2,7.6H40.2z M42.7,23.7h3.1l0.5,1.2h-4L42.7,23.7z"
      />
      <path
        id="R"
        className="letter"
        d="M23.3,18.2c0.5,0,0.9,0.1,1.4,0.2c0.4,0.1,0.8,0.3,1,0.5c0.3,0.2,0.5,0.5,0.7,0.9c0.2,0.3,0.2,0.7,0.2,1.2
		c0,0.3-0.1,0.7-0.2,1s-0.3,0.7-0.5,0.9c-0.2,0.3-0.6,0.5-1,0.7s-0.9,0.3-1.5,0.3h-1.3v3h-1.4v-8.7H23.3z M23.5,22.5
		c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3-0.1-0.5
		c0-0.2-0.1-0.3-0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.8-0.2h-1.3v3H23.5z M25.1,23.4l2.3,3.6h-1.7l-2.3-3.5
		L25.1,23.4z"
      />
      <path
        id="D"
        className="letter"
        d="M0,26.9v-8.7h2.5c0.9,0,1.6,0.1,2.3,0.4s1.1,0.6,1.5,1C6.7,20,7,20.5,7.2,21.1c0.2,0.5,0.3,1.1,0.3,1.6
		c0,0.7-0.1,1.2-0.4,1.8c-0.2,0.5-0.6,1-1,1.3c-0.4,0.4-0.9,0.7-1.4,0.9c-0.5,0.2-1.1,0.3-1.7,0.3H0z M1.4,25.6h1.3
		c0.5,0,0.9-0.1,1.3-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.3,0.5-0.6,0.7-0.9C5.9,23.5,6,23,6,22.6c0-0.5-0.1-1-0.3-1.4
		c-0.2-0.4-0.4-0.7-0.7-0.9s-0.6-0.4-1-0.5s-0.7-0.2-1-0.2H1.4V25.6z"
      />
    </g>
    <path
      id="bar_top"
      className="st2"
      d="M0,0h87v1.5H0V0z"
      data-svg-origin="0 0.75"
      transform="matrix(0,0,0,1,0,0)"
    />
    <path
      id="bar_bottom"
      className="st4"
      d="M20,25.5h87V27H20V25.5z"
      data-svg-origin="107 26.25"
      transform="matrix(0,0,0,1,107,0)"
    />

    <path
      id="D_masked"
      d="M107,18.3V27h-2.5c-0.9,0-1.6-0.1-2.3-0.4s-1.1-0.6-1.5-1c-0.4-0.4-0.7-0.9-0.8-1.4c-0.2-0.5-0.3-1.1-0.3-1.6
	c0-0.7,0.1-1.2,0.4-1.8c0.2-0.5,0.6-1,1-1.3c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1.1-0.3,1.7-0.3H107z M105.6,19.6h-1.3
	c-0.5,0-0.9,0.1-1.3,0.2s-0.7,0.3-1,0.6c-0.3,0.3-0.5,0.6-0.7,0.9c-0.2,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.3,1.4
	c0.2,0.4,0.4,0.7,0.7,0.9s0.6,0.4,1,0.5c0.4,0.1,0.7,0.2,1,0.2h1.5V19.6z"
      data-svg-origin="107 22"
      transform="matrix(1,0,0,1,20,0)"
    />

    <path
      id="B_masked"
      d="M2.4,0c0.9,0,1.6,0.2,2.2,0.5c0.5,0.4,0.8,0.9,0.8,1.7c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.3-0.6,0.6-1,0.8
	C3.6,4.5,3.2,4.6,2.6,4.6L2.4,3.8c0.7,0,1.3,0.1,1.8,0.3C4.7,4.3,5.1,4.6,5.4,5c0.3,0.4,0.4,0.8,0.4,1.3c0,0.5-0.1,0.8-0.3,1.2
	C5.4,7.7,5.2,8,4.9,8.2c-0.3,0.2-0.6,0.3-1,0.4C3.6,8.7,3.2,8.7,2.8,8.7H0V0H2.4z M2.7,3.7c0.4,0,0.8-0.1,1-0.4
	c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.2-0.6-0.3-1-0.3H1.4v2.4H2.7z M2.7,7.4c0.3,0,0.6,0,0.8-0.1C3.8,7.2,4,7.1,4.1,6.9c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.3-0.7C3.9,5.4,3.7,5.2,3.4,5.2C3.2,5.1,2.9,5.1,2.6,5.1H1.4v2.4
	H2.7z"
      data-svg-origin="1 4.999875677720411"
      transform="matrix(1,0,0,1,-20,0)"
    />
  </svg>
);

export default IconLogo;
