/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';

const IconContact = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 361.51 169.31">
    <g id="main">
      <a href="https://github.com/buligadragos" id="git-txt">
        <path
          d="M205.44,160.82v7.51a3.39,3.39,0,0,1-.53,1.85,3.58,3.58,0,0,1-1.48,1.31,4.89,4.89,0,0,1-2.26.49,4.61,4.61,0,0,1-1.95-.38,6.43,6.43,0,0,1-1.65-1.12l1-1.15a5.1,5.1,0,0,0,1.22.88,3.17,3.17,0,0,0,1.35.29,2.79,2.79,0,0,0,1.92-.59,2.07,2.07,0,0,0,.69-1.6l0-1.41a3,3,0,0,1-1.21,1.13,3.94,3.94,0,0,1-1.85.42,3.59,3.59,0,0,1-1.89-.52,4,4,0,0,1-1.89-3.42,4,4,0,0,1,2-3.48,3.88,3.88,0,0,1,1.93-.52,4.12,4.12,0,0,1,1.76.38,2.86,2.86,0,0,1,1.21,1l.13-1Zm-2.6,5.72a2.37,2.37,0,0,0,.92-1.36V163.7a2.45,2.45,0,0,0-.95-1.31,2.75,2.75,0,0,0-1.62-.49,2.53,2.53,0,0,0-1.84.72,2.46,2.46,0,0,0-.74,1.83,2.58,2.58,0,0,0,.35,1.32,2.56,2.56,0,0,0,2.23,1.29A2.66,2.66,0,0,0,202.84,166.54Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M208.23,157.47a1,1,0,0,1,1.39,0,.89.89,0,0,1,0,1.33,1,1,0,0,1-1.39,0,.87.87,0,0,1,0-1.33Zm1.49,3.35v7.45h-1.67v-7.45Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M214.74,157.54v3.3h2v1.33h-2v6.1h-1.69v-6.1h-1.37v-1.33h1.37v-3.3Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M224.86,161.15a2.15,2.15,0,0,1,.7,1.61v5.51h-1.69v-4.93a1.53,1.53,0,0,0-.38-1,1.4,1.4,0,0,0-1-.38,2,2,0,0,0-1.17.36,2.41,2.41,0,0,0-.81,1,3.37,3.37,0,0,0-.3,1.44v3.49H218.5v-14h1.64v7.85a3.19,3.19,0,0,1,1.2-1.19,3.53,3.53,0,0,1,1.79-.45A2.5,2.5,0,0,1,224.86,161.15Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M235.08,160.82v7.45h-1.51l-.11-1.42a3,3,0,0,1-1.17,1.23,3.32,3.32,0,0,1-1.76.48,2.38,2.38,0,0,1-1.69-.62,2.3,2.3,0,0,1-.69-1.61v-5.51h1.68v4.91a1.55,1.55,0,0,0,.36,1,1.33,1.33,0,0,0,1,.39,1.84,1.84,0,0,0,1.12-.37,2.5,2.5,0,0,0,.8-1,3.37,3.37,0,0,0,.3-1.44v-3.47Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M244.31,161a3.48,3.48,0,0,1,1.32,1.37,4.56,4.56,0,0,1,0,4.19,3.52,3.52,0,0,1-1.41,1.4,3.9,3.9,0,0,1-1.93.49,3.79,3.79,0,0,1-1.67-.36,3.23,3.23,0,0,1-1.21-1v1.15h-1.68v-14h1.68v7.49a3.23,3.23,0,0,1,1.28-.95,4.64,4.64,0,0,1,1.81-.35A3.68,3.68,0,0,1,244.31,161Zm-.62,5.27a2.54,2.54,0,0,0,.72-1.85,2.64,2.64,0,0,0-.34-1.31,2.49,2.49,0,0,0-2.18-1.26,2.72,2.72,0,0,0-1.62.49,2.24,2.24,0,0,0-.9,1.31v1.51a2.38,2.38,0,0,0,.9,1.33,2.56,2.56,0,0,0,1.62.52A2.35,2.35,0,0,0,243.69,166.27Z"
          transform="translate(-2.92 -1.56)"
        />
      </a>

      <g className="git-lines">
        <line className="git-line" x1="113.39" y1="139.02" x2="113.39" y2="157.02" />
        <line className="git-line" x1="322.39" y1="139.02" x2="322.39" y2="157.02" />
        <line className="git-line" x1="322.39" y1="148.02" x2="113.39" y2="148.02" />
      </g>

      <a href="https://www.instagram.com/buliga.dragos/" id="insta-txt">
        <path
          d="M163.12,49.16a1,1,0,0,1,1.39,0,.89.89,0,0,1,0,1.33,1,1,0,0,1-1.39,0,.87.87,0,0,1,0-1.33Zm1.49,3.34V60h-1.67V52.5Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M173.57,52.84a2.1,2.1,0,0,1,.7,1.61V60h-1.69V55a1.48,1.48,0,0,0-.38-1,1.38,1.38,0,0,0-1-.37,2,2,0,0,0-1.64.79,3.09,3.09,0,0,0-.65,2V60h-1.67V52.5h1.51l.11,1.44A2.92,2.92,0,0,1,170,52.67a3.57,3.57,0,0,1,1.81-.45A2.43,2.43,0,0,1,173.57,52.84Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M177.4,59.83a2.83,2.83,0,0,1-1.22-.85l.72-1a3.67,3.67,0,0,0,.95.67,2.34,2.34,0,0,0,1,.23,1.88,1.88,0,0,0,1-.23.88.88,0,0,0,.14-1.33,1.44,1.44,0,0,0-.53-.35c-.19-.07-.53-.18-1-.32-1.27-.41-1.9-1.13-1.9-2.18a2.14,2.14,0,0,1,.69-1.6,2.69,2.69,0,0,1,1.93-.67,4.66,4.66,0,0,1,1.41.2,3.33,3.33,0,0,1,1.13.63l-.66,1a2.09,2.09,0,0,0-.73-.49,2.83,2.83,0,0,0-.84-.2,1.43,1.43,0,0,0-.86.25.75.75,0,0,0-.36.65.74.74,0,0,0,.23.57,1.42,1.42,0,0,0,.49.32c.16.07.49.18,1,.34a3.8,3.8,0,0,1,1.43.79,1.72,1.72,0,0,1,.51,1.31,2.33,2.33,0,0,1-.76,1.79,3,3,0,0,1-2.14.7A4.37,4.37,0,0,1,177.4,59.83Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M186.32,49.23v3.29h2v1.33h-2V60h-1.7V53.85h-1.36V52.52h1.36V49.23Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M197.73,52.5V60h-1.68V58.73a3.64,3.64,0,0,1-1.15,1,3.26,3.26,0,0,1-1.71.45,4.06,4.06,0,0,1-1.92-.46,3.39,3.39,0,0,1-1.34-1.34,4.15,4.15,0,0,1-.5-2.11,4.25,4.25,0,0,1,.51-2.13,3.69,3.69,0,0,1,1.39-1.41,3.85,3.85,0,0,1,1.93-.49,3.27,3.27,0,0,1,1.66.41,3.34,3.34,0,0,1,1.13,1.08l.13-1.19Zm-2.51,5.74a2.34,2.34,0,0,0,.83-1.4V55.37a2.26,2.26,0,0,0-.9-1.29,2.57,2.57,0,0,0-1.56-.5,2.42,2.42,0,0,0-1.24.33,2.36,2.36,0,0,0-.89.91,2.55,2.55,0,0,0-.34,1.32,2.64,2.64,0,0,0,.34,1.31,2.7,2.7,0,0,0,.92.95,2.44,2.44,0,0,0,1.26.35A2.51,2.51,0,0,0,195.22,58.24Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M208.2,52.5V60a3.38,3.38,0,0,1-.52,1.85,3.66,3.66,0,0,1-1.48,1.32,5,5,0,0,1-2.26.48,4.62,4.62,0,0,1-1.95-.37,7,7,0,0,1-1.65-1.12l1-1.15a5.12,5.12,0,0,0,1.22.87,3,3,0,0,0,1.35.3,2.8,2.8,0,0,0,1.92-.6,2.06,2.06,0,0,0,.69-1.6l0-1.4a3,3,0,0,1-1.21,1.12,3.94,3.94,0,0,1-1.85.43,3.6,3.6,0,0,1-1.89-.53,3.84,3.84,0,0,1-1.38-1.42,4,4,0,0,1-.51-2,4,4,0,0,1,.53-2,3.92,3.92,0,0,1,1.43-1.43,3.75,3.75,0,0,1,1.93-.52,4,4,0,0,1,1.76.38,2.85,2.85,0,0,1,1.21,1l.12-1Zm-2.59,5.73a2.39,2.39,0,0,0,.92-1.37V55.38a2.48,2.48,0,0,0-.95-1.3,2.75,2.75,0,0,0-1.62-.5,2.52,2.52,0,0,0-1.84.72,2.48,2.48,0,0,0-.74,1.84,2.64,2.64,0,0,0,.34,1.31,2.59,2.59,0,0,0,.94.95,2.56,2.56,0,0,0,1.3.35A2.68,2.68,0,0,0,205.61,58.23Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M213.25,53.19a4.31,4.31,0,0,1,1.13-.72,2.91,2.91,0,0,1,1-.27l-.07,1.67a2.8,2.8,0,0,0-1.47.33,2.56,2.56,0,0,0-1,1,2.65,2.65,0,0,0-.37,1.36V60h-1.68V52.5h1.5l.12,1.77A3.44,3.44,0,0,1,213.25,53.19Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M224.93,52.5V60h-1.68V58.73a3.76,3.76,0,0,1-1.15,1,3.26,3.26,0,0,1-1.71.45,4.06,4.06,0,0,1-1.92-.46,3.41,3.41,0,0,1-1.35-1.34,4.74,4.74,0,0,1,0-4.24,3.56,3.56,0,0,1,1.39-1.41,3.82,3.82,0,0,1,1.93-.49,3.27,3.27,0,0,1,1.66.41,3.44,3.44,0,0,1,1.13,1.08l.13-1.19Zm-2.51,5.74a2.34,2.34,0,0,0,.83-1.4V55.37a2.26,2.26,0,0,0-.9-1.29,2.59,2.59,0,0,0-1.56-.5,2.42,2.42,0,0,0-1.24.33,2.38,2.38,0,0,0-.9.91,2.64,2.64,0,0,0-.33,1.32,2.54,2.54,0,0,0,.34,1.31,2.7,2.7,0,0,0,.92.95,2.39,2.39,0,0,0,1.26.35A2.51,2.51,0,0,0,222.42,58.24Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M238.81,52.81a2.28,2.28,0,0,1,.68,1.62V60h-1.68V55c0-.87-.43-1.34-1.18-1.38a1.89,1.89,0,0,0-1.17.38,2.72,2.72,0,0,0-.8,1,3.41,3.41,0,0,0-.3,1.35V60h-1.69V55c0-.87-.45-1.34-1.23-1.38a1.82,1.82,0,0,0-1.14.38,2.59,2.59,0,0,0-.8,1,3.29,3.29,0,0,0-.29,1.36V60h-1.67V52.5h1.51l.12,1.41a3.19,3.19,0,0,1,1.18-1.26,3.25,3.25,0,0,1,1.7-.45,2.64,2.64,0,0,1,1.52.42,1.94,1.94,0,0,1,.79,1.16,3.47,3.47,0,0,1,1.19-1.16,3,3,0,0,1,1.54-.42A2.36,2.36,0,0,1,238.81,52.81Z"
          transform="translate(-2.92 -1.56)"
        />
      </a>
      <g className="insta-lines">
        <line className="insta-line" x1="75.39" y1="58.02" x2="75.39" y2="76.02" />
        <line className="insta-line" x1="322.39" y1="58.02" x2="322.39" y2="76.02" />
        <line className="insta-line" x1="322.39" y1="67.02" x2="75.39" y2="67.02" />
      </g>

      <a href="mailto:hello@buligadragos.ro" id="email-txt">
        <path
          d="M185.67,13.6l.77,1.24a5.23,5.23,0,0,1-1.28.62,4.4,4.4,0,0,1-1.35.23,4.29,4.29,0,0,1-2.16-.52,3.62,3.62,0,0,1-1.44-1.41,4.15,4.15,0,0,1-.5-2,3.8,3.8,0,0,1,.55-2,4,4,0,0,1,1.5-1.44,4.27,4.27,0,0,1,2.09-.53,3.58,3.58,0,0,1,2.44.85A4.43,4.43,0,0,1,187.63,11l-5.9,2.07a2.27,2.27,0,0,0,.87.87,2.47,2.47,0,0,0,1.25.32A3.07,3.07,0,0,0,185.67,13.6ZM182,9.8a2.61,2.61,0,0,0-.66,1.84c0,.18,0,.32,0,.41l4.34-1.6a2.44,2.44,0,0,0-.76-1,2,2,0,0,0-1.24-.38A2.21,2.21,0,0,0,182,9.8Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M200.69,8.36a2.27,2.27,0,0,1,.67,1.62v5.53h-1.67V10.59c0-.87-.43-1.34-1.19-1.38a1.92,1.92,0,0,0-1.16.38,2.62,2.62,0,0,0-.8,1,3.6,3.6,0,0,0-.31,1.35v3.55h-1.69V10.59c0-.87-.44-1.34-1.22-1.38a1.88,1.88,0,0,0-1.14.38,2.61,2.61,0,0,0-.81,1,3.31,3.31,0,0,0-.28,1.36v3.53h-1.68V8.05h1.51l.13,1.41a3.19,3.19,0,0,1,1.18-1.26,3.22,3.22,0,0,1,1.7-.45,2.61,2.61,0,0,1,1.51.42,1.9,1.9,0,0,1,.79,1.16,3.56,3.56,0,0,1,1.19-1.16A3,3,0,0,1,199,7.75,2.38,2.38,0,0,1,200.69,8.36Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M211.61,8.05v7.46h-1.68V14.28a3.92,3.92,0,0,1-1.15,1,3.35,3.35,0,0,1-1.71.44,4.06,4.06,0,0,1-1.92-.46,3.35,3.35,0,0,1-1.35-1.34,4.25,4.25,0,0,1-.49-2.11,4.25,4.25,0,0,1,.51-2.13,3.56,3.56,0,0,1,1.39-1.41,3.82,3.82,0,0,1,1.93-.49,3.27,3.27,0,0,1,1.66.41,3.44,3.44,0,0,1,1.13,1.08l.13-1.19Zm-2.51,5.74a2.31,2.31,0,0,0,.83-1.4V10.92a2.26,2.26,0,0,0-.9-1.29,2.59,2.59,0,0,0-1.56-.5,2.42,2.42,0,0,0-1.24.33,2.38,2.38,0,0,0-.9.91,2.64,2.64,0,0,0-.33,1.32,2.54,2.54,0,0,0,.34,1.31,2.62,2.62,0,0,0,.92.95,2.39,2.39,0,0,0,1.26.35A2.51,2.51,0,0,0,209.1,13.79Z"
          transform="translate(-2.92 -1.56)"
        />
        <path
          d="M214.4,4.71a1,1,0,0,1,1.39,0,.89.89,0,0,1,0,1.33,1,1,0,0,1-1.39,0,.87.87,0,0,1,0-1.33Zm1.49,3.34v7.46h-1.67V8.05Z"
          transform="translate(-2.92 -1.56)"
        />
        <path d="M220.17,1.56v14H218.5V1.56Z" transform="translate(-2.92 -1.56)" />
      </a>
      <g className="email-lines">
        <line className="cls-1" x1="0.39" y1="13.02" x2="0.39" y2="31.02" />
        <line className="cls-1" x1="361.39" y1="13.02" x2="361.39" y2="31.02" />
        <line className="cls-1" x1="361.39" y1="22.02" x2="0.39" y2="22.02" />
      </g>

      <a href="mailto:hello@buligadragos.ro" id="hi-txt">
        <text className="cls-3" transform="translate(23.8 168.21)">
          👋
        </text>
      </a>
      <g className="hi-lines">
        <line className="cls-1" x1="0.39" y1="139.02" x2="0.39" y2="157.02" />
        <line className="cls-1" x1="72.39" y1="139.02" x2="72.39" y2="157.02" />
        <line className="cls-1" x1="72.39" y1="148.02" x2="0.39" y2="148.02" />
      </g>

      <path
        className="hello-name"
        d="M15.62,109A4.28,4.28,0,0,1,17,112.2v11H13.64v-9.86a3,3,0,0,0-.75-2,2.84,2.84,0,0,0-2.05-.76,4,4,0,0,0-2.34.72,4.73,4.73,0,0,0-1.64,2,6.75,6.75,0,0,0-.6,2.88v7H2.92V95.31H6.19V111a6.17,6.17,0,0,1,2.4-2.38,7,7,0,0,1,3.58-.9A5,5,0,0,1,15.62,109Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="hello-name"
        d="M33.44,119.4,35,121.88a10.34,10.34,0,0,1-2.57,1.24,8.7,8.7,0,0,1-2.68.45,8.84,8.84,0,0,1-4.34-1,7.21,7.21,0,0,1-2.86-2.83,8.11,8.11,0,0,1-1-4.07,7.54,7.54,0,0,1,1.1-4,8,8,0,0,1,3-2.88,8.44,8.44,0,0,1,4.18-1.06,7.17,7.17,0,0,1,4.88,1.71,8.83,8.83,0,0,1,2.68,4.77l-11.81,4.14a4.64,4.64,0,0,0,1.75,1.74,4.91,4.91,0,0,0,2.5.63A6.06,6.06,0,0,0,33.44,119.4Zm-7.27-7.6a5.18,5.18,0,0,0-1.33,3.67,5.9,5.9,0,0,0,0,.83l8.67-3.2a4.9,4.9,0,0,0-1.53-2,3.9,3.9,0,0,0-2.46-.76A4.42,4.42,0,0,0,26.17,111.8Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="hello-name"
        d="M44.89,95.31v27.9H41.54V95.31Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="hello-name"
        d="M54.07,95.31v27.9H50.72V95.31Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="hello-name"
        d="M59.67,111.6a7.65,7.65,0,0,1,2.95-2.86,8.63,8.63,0,0,1,4.27-1,8.45,8.45,0,0,1,4.23,1A7.56,7.56,0,0,1,74,111.58a8.06,8.06,0,0,1,1,4,8.18,8.18,0,0,1-1,4,7.45,7.45,0,0,1-2.9,2.86,8.73,8.73,0,0,1-4.29,1,8.87,8.87,0,0,1-4.21-1,7.29,7.29,0,0,1-2.93-2.79,8,8,0,0,1-1.06-4.17A7.79,7.79,0,0,1,59.67,111.6Zm2.95,6.64a4.83,4.83,0,0,0,1.73,1.88,4.54,4.54,0,0,0,2.43.68,4.48,4.48,0,0,0,3.45-1.48,5.14,5.14,0,0,0,1.37-3.67A5.24,5.24,0,0,0,70.23,112a4.49,4.49,0,0,0-3.45-1.49,4.39,4.39,0,0,0-2.45.7,5,5,0,0,0-1.71,1.89,5.64,5.64,0,0,0,0,5.18Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="at-name"
        d="M105.45,101.67a11.82,11.82,0,0,1,4.86,4.55,12.54,12.54,0,0,1,1.7,6.48,12.67,12.67,0,0,1-1.14,5.36,9.85,9.85,0,0,1-3.15,4,7.38,7.38,0,0,1-4.53,1.5,4.27,4.27,0,0,1-2.7-.85,3.94,3.94,0,0,1-1.41-2.36,10.51,10.51,0,0,1-2.88,2.29,7.19,7.19,0,0,1-3.49.95,7.42,7.42,0,0,1-3.55-.84,6,6,0,0,1-2.43-2.42,7.16,7.16,0,0,1-.82-3.65A9.49,9.49,0,0,1,87,112.12a8.27,8.27,0,0,1,7.51-4.42q4.06,0,5.51,3.2l.65-2.59h3.16L102.5,118a5.21,5.21,0,0,0,0,.65c0,1.2.5,1.8,1.51,1.8a3.45,3.45,0,0,0,2.34-1,7.73,7.73,0,0,0,1.82-2.83,10.65,10.65,0,0,0,.7-3.87,10,10,0,0,0-1.33-5.09,9.41,9.41,0,0,0-3.89-3.62,13,13,0,0,0-6.09-1.33,16.41,16.41,0,0,0-9.5,2.5,14.51,14.51,0,0,0-5.18,5.9,15.06,15.06,0,0,0-1.55,6.21,12.2,12.2,0,0,0,1.47,6.23,9.14,9.14,0,0,0,4.07,3.74,13.87,13.87,0,0,0,6,1.23,15,15,0,0,0,8-2.45l1.4,1.94a14,14,0,0,1-4.66,2.34,18.18,18.18,0,0,1-5.24.8,16.36,16.36,0,0,1-7.22-1.55,11.85,11.85,0,0,1-5-4.63,14.33,14.33,0,0,1-1.85-7.5,17.26,17.26,0,0,1,8.64-14.73A21.21,21.21,0,0,1,98,100,15.58,15.58,0,0,1,105.45,101.67Zm-8.31,18.1a5.59,5.59,0,0,0,2-2.79l.4-3a3.69,3.69,0,0,0-1.44-2.57,4.86,4.86,0,0,0-3.1-1,5.1,5.1,0,0,0-2.77.79,5.93,5.93,0,0,0-2,2.12,5.64,5.64,0,0,0-.76,2.85,4.49,4.49,0,0,0,1.26,3.27A4.35,4.35,0,0,0,94,120.8,5.15,5.15,0,0,0,97.14,119.77Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M129.74,108.67a7,7,0,0,1,2.64,2.73,9.18,9.18,0,0,1-.07,8.39,7.12,7.12,0,0,1-2.82,2.81,7.94,7.94,0,0,1-3.87,1,7.53,7.53,0,0,1-3.33-.72,6.6,6.6,0,0,1-2.43-1.94v2.3h-3.35V95.31h3.35v15a6.41,6.41,0,0,1,2.57-1.89,9.06,9.06,0,0,1,3.62-.7A7.22,7.22,0,0,1,129.74,108.67Zm-1.23,10.55a5.14,5.14,0,0,0,1.43-3.71,5.23,5.23,0,0,0-.67-2.61,5,5,0,0,0-4.37-2.54,5.42,5.42,0,0,0-3.23,1,4.54,4.54,0,0,0-1.81,2.61v3a4.79,4.79,0,0,0,1.8,2.67,5.15,5.15,0,0,0,3.24,1A4.79,4.79,0,0,0,128.51,119.22Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M151.72,108.31v14.9h-3l-.21-2.84a6,6,0,0,1-2.34,2.46,6.71,6.71,0,0,1-3.53,1,4.75,4.75,0,0,1-3.37-1.24,4.48,4.48,0,0,1-1.38-3.23v-11h3.34v9.83a3.16,3.16,0,0,0,.74,2,2.67,2.67,0,0,0,2,.78,3.69,3.69,0,0,0,2.23-.74,5,5,0,0,0,1.6-2,6.81,6.81,0,0,0,.6-2.88v-7Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M160.9,95.31v27.9h-3.35V95.31Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M167.09,101.61a2.05,2.05,0,0,1,1.4-.54,2,2,0,0,1,1.39.54,1.81,1.81,0,0,1,0,2.67,2,2,0,0,1-1.39.54,2.05,2.05,0,0,1-1.4-.54,1.77,1.77,0,0,1,0-2.67Zm3,6.7v14.9h-3.35v-14.9Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M191.64,108.31v15a6.87,6.87,0,0,1-1,3.71,7.37,7.37,0,0,1-3,2.63,10,10,0,0,1-4.51,1,9.18,9.18,0,0,1-3.91-.76,13.48,13.48,0,0,1-3.29-2.23l2-2.3a9.82,9.82,0,0,0,2.45,1.74,6,6,0,0,0,2.7.6,5.63,5.63,0,0,0,3.83-1.19,4.18,4.18,0,0,0,1.39-3.21l0-2.8a6,6,0,0,1-2.43,2.25,7.84,7.84,0,0,1-3.69.84,7.22,7.22,0,0,1-3.78-1,7.8,7.8,0,0,1-2.75-2.84,8.35,8.35,0,0,1,0-8.09,7.8,7.8,0,0,1,2.87-2.86,7.47,7.47,0,0,1,3.85-1,8.31,8.31,0,0,1,3.53.75,5.69,5.69,0,0,1,2.41,2l.25-2.09Zm-5.18,11.45a4.84,4.84,0,0,0,1.83-2.74v-3a4.85,4.85,0,0,0-1.91-2.61,5.45,5.45,0,0,0-3.24-1,5.06,5.06,0,0,0-3.67,1.44,4.91,4.91,0,0,0-1.47,3.67,5.16,5.16,0,0,0,2.55,4.52,5,5,0,0,0,2.59.7A5.45,5.45,0,0,0,186.46,119.76Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M212.77,108.31v14.9h-3.35v-2.45a7.56,7.56,0,0,1-2.3,1.93,6.65,6.65,0,0,1-3.42.88,7.86,7.86,0,0,1-3.83-.92,6.63,6.63,0,0,1-2.7-2.68,8.46,8.46,0,0,1-1-4.21,8.62,8.62,0,0,1,1-4.27,7.31,7.31,0,0,1,2.77-2.8,7.74,7.74,0,0,1,3.87-1,6.44,6.44,0,0,1,3.32.82,6.8,6.8,0,0,1,2.26,2.17l.26-2.38Zm-5,11.46a4.52,4.52,0,0,0,1.67-2.79v-3a4.53,4.53,0,0,0-1.8-2.57,5.19,5.19,0,0,0-3.13-1,4.86,4.86,0,0,0-2.46.65,4.63,4.63,0,0,0-1.8,1.82,5.16,5.16,0,0,0-.67,2.64,5.23,5.23,0,0,0,.68,2.63,5.29,5.29,0,0,0,1.84,1.89,4.74,4.74,0,0,0,2.52.7A5,5,0,0,0,207.75,119.77Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M234.15,95.31v27.9h-3.38v-2.34a7.6,7.6,0,0,1-2.65,2,8.11,8.11,0,0,1-3.43.72,7.13,7.13,0,0,1-3.78-1,7,7,0,0,1-2.65-2.82,8.85,8.85,0,0,1-.95-4.2,8.41,8.41,0,0,1,1-4.19,6.86,6.86,0,0,1,2.78-2.72,8.39,8.39,0,0,1,4-.93,7.84,7.84,0,0,1,3.26.7,6.09,6.09,0,0,1,2.47,1.92v-15ZM229,119.65a4.73,4.73,0,0,0,1.75-2.81V114a4.7,4.7,0,0,0-1.83-2.64,5.28,5.28,0,0,0-3.21-1,5,5,0,0,0-4.35,2.5,5.11,5.11,0,0,0-.69,2.65,5.17,5.17,0,0,0,.69,2.63,5.05,5.05,0,0,0,1.83,1.87,4.91,4.91,0,0,0,2.52.68A5.18,5.18,0,0,0,229,119.65Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M244.83,109.68a8.09,8.09,0,0,1,2.25-1.44,5.92,5.92,0,0,1,2.09-.54L249,111a5.53,5.53,0,0,0-2.93.65,5.19,5.19,0,0,0-2.05,2,5.28,5.28,0,0,0-.74,2.72v6.8H240v-14.9h3l.25,3.53A6.46,6.46,0,0,1,244.83,109.68Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M268.43,108.31v14.9h-3.35v-2.45a7.73,7.73,0,0,1-2.31,1.93,6.6,6.6,0,0,1-3.42.88,7.89,7.89,0,0,1-3.83-.92,6.63,6.63,0,0,1-2.7-2.68,9.41,9.41,0,0,1,0-8.48,7.17,7.17,0,0,1,2.77-2.8,7.69,7.69,0,0,1,3.87-1,6.43,6.43,0,0,1,3.31.82,6.84,6.84,0,0,1,2.27,2.17l.25-2.38Zm-5,11.46a4.53,4.53,0,0,0,1.68-2.79v-3a4.62,4.62,0,0,0-1.8-2.57,5.21,5.21,0,0,0-3.13-1,4.93,4.93,0,0,0-2.47.65,4.63,4.63,0,0,0-1.8,1.82,5.26,5.26,0,0,0-.67,2.64,5.14,5.14,0,0,0,.69,2.63,5.26,5.26,0,0,0,1.83,1.89,4.74,4.74,0,0,0,2.52.7A4.91,4.91,0,0,0,263.4,119.77Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M290,108.31v15a6.87,6.87,0,0,1-1,3.71,7.4,7.4,0,0,1-3,2.63,10,10,0,0,1-4.52,1,9.18,9.18,0,0,1-3.91-.76,13.48,13.48,0,0,1-3.29-2.23l2-2.3a9.82,9.82,0,0,0,2.45,1.74,6,6,0,0,0,2.7.6,5.63,5.63,0,0,0,3.83-1.19,4.18,4.18,0,0,0,1.39-3.21l0-2.8a6,6,0,0,1-2.43,2.25,7.84,7.84,0,0,1-3.69.84,7.22,7.22,0,0,1-3.78-1,7.8,7.8,0,0,1-2.75-2.84,7.92,7.92,0,0,1-1-4A8,8,0,0,1,274,111.6a7.87,7.87,0,0,1,2.87-2.86,7.5,7.5,0,0,1,3.85-1,8.31,8.31,0,0,1,3.53.75,5.69,5.69,0,0,1,2.41,2l.25-2.09Zm-5.18,11.45a4.84,4.84,0,0,0,1.83-2.74v-3a4.85,4.85,0,0,0-1.91-2.61,5.45,5.45,0,0,0-3.24-1,5.06,5.06,0,0,0-3.67,1.44,4.91,4.91,0,0,0-1.47,3.67,5.13,5.13,0,0,0,.68,2.63,5.21,5.21,0,0,0,1.87,1.89,5,5,0,0,0,2.59.7A5.45,5.45,0,0,0,284.81,119.76Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M295.59,111.6a7.65,7.65,0,0,1,3-2.86,8.63,8.63,0,0,1,4.27-1,8.45,8.45,0,0,1,4.23,1,7.56,7.56,0,0,1,2.88,2.84,8.06,8.06,0,0,1,1,4,8.18,8.18,0,0,1-1,4,7.45,7.45,0,0,1-2.9,2.86,8.73,8.73,0,0,1-4.29,1,8.87,8.87,0,0,1-4.21-1,7.29,7.29,0,0,1-2.93-2.79,8,8,0,0,1-1.06-4.17A7.79,7.79,0,0,1,295.59,111.6Zm3,6.64a4.83,4.83,0,0,0,1.73,1.88,4.54,4.54,0,0,0,2.43.68,4.48,4.48,0,0,0,3.45-1.48,5.14,5.14,0,0,0,1.37-3.67,5.24,5.24,0,0,0-1.37-3.69,4.49,4.49,0,0,0-3.45-1.49,4.39,4.39,0,0,0-2.45.7,5,5,0,0,0-1.71,1.89,5.64,5.64,0,0,0,0,5.18Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="name-name"
        d="M316.5,123a5.92,5.92,0,0,1-2.43-1.69l1.44-2a7.06,7.06,0,0,0,1.89,1.34,4.61,4.61,0,0,0,2,.46,3.64,3.64,0,0,0,1.94-.46,1.59,1.59,0,0,0,.76-1.44,1.67,1.67,0,0,0-.47-1.23,2.87,2.87,0,0,0-1.06-.68c-.4-.15-1.07-.36-2-.65-2.55-.81-3.82-2.27-3.82-4.36a4.27,4.27,0,0,1,1.39-3.2,5.33,5.33,0,0,1,3.87-1.33,9.31,9.31,0,0,1,2.81.39,7,7,0,0,1,2.26,1.26l-1.33,2.06a4,4,0,0,0-1.46-1,5.1,5.1,0,0,0-1.67-.39,3.05,3.05,0,0,0-1.73.48,1.58,1.58,0,0,0-.25,2.45,3,3,0,0,0,1,.65c.34.13,1,.35,2,.67a7.51,7.51,0,0,1,2.86,1.58,3.43,3.43,0,0,1,1,2.63,4.63,4.63,0,0,1-1.51,3.56,6.06,6.06,0,0,1-4.29,1.41A8.8,8.8,0,0,1,316.5,123Z"
        transform="translate(-2.92 -2.19)"
      />
      <path
        className="ro"
        d="M329,118.27a1.86,1.86,0,0,1,1.34-.57,1.8,1.8,0,0,1,1.27.57,1.84,1.84,0,0,1,.6,1.37,1.91,1.91,0,0,1-1.87,1.91A1.91,1.91,0,0,1,329,121a1.81,1.81,0,0,1-.57-1.37A1.89,1.89,0,0,1,329,118.27Z"
        transform="translate(-2.92 -1.38)"
      />
      <path
        className="ro"
        d="M341.09,108a8.57,8.57,0,0,1,2.25-1.44,6.39,6.39,0,0,1,2.09-.54l-.15,3.35a5.48,5.48,0,0,0-2.93.65,5.26,5.26,0,0,0-2.05,2,5.34,5.34,0,0,0-.74,2.71v6.81h-3.35V106.64h3l.25,3.53A6.81,6.81,0,0,1,341.09,108Z"
        transform="translate(-2.92 -1.38)"
      />
      <path
        className="ro"
        d="M348.87,109.94a7.52,7.52,0,0,1,2.95-2.86,8.63,8.63,0,0,1,4.27-1,8.39,8.39,0,0,1,4.22,1,7.35,7.35,0,0,1,2.88,2.84,7.93,7.93,0,0,1,1,4,8,8,0,0,1-1,4,7.3,7.3,0,0,1-2.89,2.86,8.63,8.63,0,0,1-4.29,1,8.87,8.87,0,0,1-4.21-1,7.23,7.23,0,0,1-2.93-2.79,8,8,0,0,1-1.06-4.18A7.78,7.78,0,0,1,348.87,109.94Zm2.95,6.64a4.73,4.73,0,0,0,1.73,1.87,4.47,4.47,0,0,0,2.43.69,4.51,4.51,0,0,0,3.45-1.48,5.63,5.63,0,0,0,0-7.36,4.46,4.46,0,0,0-3.45-1.5,4.37,4.37,0,0,0-2.45.71,4.89,4.89,0,0,0-1.71,1.89,5.64,5.64,0,0,0,0,5.18Z"
        transform="translate(-2.92 -1.38)"
      />
    </g>
  </svg>
);

export default IconContact;
