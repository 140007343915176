import React from 'react';

const IconExternal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-external-link">
    <defs>
      <linearGradient id="svg-gradient" x2="0.35" y2="1">
        <stop offset="0%" stopColor="var(--color-stop)" />
        <stop offset="50%" stopColor="var(--color-stop2)" />
        <stop offset="100%" stopColor="var(--color-bot)" />
      </linearGradient>
    </defs>
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

export default IconExternal;
